const queijosData = [
    {
        id: 1,
        itemImage: require('../images/Products-pages/queijo-images/queijo-meiaCura.webp'),
        itemName: 'Queijo meia Cura'
    },
    {
        id: 2,
        itemImage: require('../images/Products-pages/queijo-images/queijo-malagueta.webp'),
        itemName: 'Queijo com Malagueta'
    },
    {
        id: 3,
        itemImage: require('../images/Products-pages/queijo-images/queijo-pintado.webp'),
        itemName: 'Queijo Curado Apimentado'
    },
]

export default queijosData